export const locksImages = [
  "https://bafybeibwvmxkiaqiukz6fzm4z3ntjc562aezwsr35eolxwgvultjrr3rni.ipfs.w3s.link/lock1.jpg",
  "https://bafybeifhlgz4pxdpxho5vx5cml54fw2h7uxh2lhcdqunr4n65tslwz6axm.ipfs.w3s.link/lock-9.jpg",
  "https://bafybeihmne3bv4k2e6vz2fry67ae3lgvmo2rwb7vhbapiwazbr5jzn7yfq.ipfs.w3s.link/lock-4.jpg",
  "https://bafybeih7nuntiwu2jc6uguazid3cwooek34uhoryyvi4fuu6db6f2yvik4.ipfs.w3s.link/lock-3.jpg",
  "https://bafybeic66lhde6bu3tr4am6k7orpe52yxqrevpdhof6jj6v3q56epzgtxa.ipfs.w3s.link/lock-8.jpg",
  "https://bafybeifwrxhc53abvoij6z2x6gi5xwqbmzr4g5oad3ywlkijyah3tzjqfm.ipfs.w3s.link/lock-1.jpg",
  "https://bafybeifxxbtf5gccv2twworknqtwvxcfkoayhwtqnyi7fn7ghimwzsxavm.ipfs.w3s.link/lock-5.jpg",
  "https://bafybeiejtqcwgdytldfvkqmi5mkyglliyo23ml35rkwp4fp7ylbvjdycky.ipfs.w3s.link/lock-6.jpg",
  "https://bafybeifkfs43eevt7gv3vtmygas53hqmftspqw7abfaippsljpzmezgpni.ipfs.w3s.link/lock-7.jpg",
];
