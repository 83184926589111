import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Show,
  SimpleGrid,
  Text,
  Hide,
} from "@chakra-ui/react";
import { logo } from "../../../accets";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { BsInstagram, BsYoutube } from "react-icons/bs";
export function Footer() {
  const naviaget = useNavigate();
  return (
    <Box bgColor="#2A2A2A">
      <Hide below="md">
        <Box px={{ base: "5", lg: "300" }} py={{ base: "5", lg: "10" }}>
          <HStack justifyContent="space-between">
            <Image src={logo} height="70px" width="180px" />
            <Button
              rightIcon={<HiOutlineArrowRight />}
              py="7"
              borderRadius="0px"
              fontSize={{ base: "lg", lg: "2xl" }}
              bgColor="#FFFFFF"
              onClick={() => naviaget("/contactUs")}
              _hover={{ bgColor: "#8BE8E5", color: "#FFFFFF" }}
            >
              Contact Us
            </Button>
          </HStack>
          <SimpleGrid
            columns={[1, 1, 3]}
            spacing={{ base: "2", lg: "20" }}
            pt={{ base: "3", lg: "10" }}
          >
            <Box color="#FFFFFF">
              <Text fontSize="md" fontWeight="600">
                Corporate office
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                Balajee Engineering Works,
              </Text>
              <Text fontSize="sm" fontWeight="400">
                Tetaria more, EKawana
              </Text>
              <Text fontSize="sm" fontWeight="400">
                Ara, Bhojpur, Bihar - 802301 INDIA
              </Text>
            </Box>
            <Box color="#FFFFFF">
              <Text fontSize="sm" fontWeight="400" pt="3">
                About Us
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                Our Businesses
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                People
              </Text>
            </Box>
            <Box color="#FFFFFF">
              <Text fontSize="sm" fontWeight="400" pt="3">
                Contact Us
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="1">
                +919650255062, +919939397519
              </Text>

              <Text fontSize="sm" fontWeight="400" pt="1">
                vishalkumar70522@gmail.com
              </Text>
              <Flex gap={{ base: "5", lg: "10" }} align="center" pt="2">
                <FaFacebook color="#FFFFFF" size="20px" />
                <BsInstagram color="#FFFFFF" size="20px" />
                <BsYoutube color="#FFFFFF" size="25px" />
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      </Hide>
      <Show below="md">
        <Box
          px={{ base: "5", lg: "300" }}
          py={{ base: "5", lg: "10" }}
          align="center"
        >
          <HStack justifyContent="space-between">
            <Image src={logo} height="70px" width="180px" />
            <Button
              rightIcon={<HiOutlineArrowRight />}
              py="7"
              borderRadius="0px"
              fontSize={{ base: "lg", lg: "2xl" }}
              bgColor="#FFFFFF"
              onClick={() => naviaget("/contactUs")}
              _hover={{ bgColor: "#f58634", color: "#FFFFFF" }}
            >
              Contact Us
            </Button>
          </HStack>
          <SimpleGrid
            columns={[1, 1, 4]}
            spacing={{ base: "2", lg: "20" }}
            pt={{ base: "3", lg: "10" }}
            align="center"
          >
            <Box color="#FFFFFF">
              <Text fontSize="md" fontWeight="600" pt="3">
                Corporate office
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                Balajee Engineering Works,
              </Text>
              <Text fontSize="sm" fontWeight="400">
                Tetaria more, EKawana
              </Text>
              <Text fontSize="sm" fontWeight="400">
                Ara, Bhojpur, Bihar - 802301 INDIA
              </Text>
            </Box>
            <Box color="#FFFFFF">
              <Text fontSize="sm" fontWeight="400" pt="3">
                About Us
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                Our Businesses
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                People
              </Text>
            </Box>
            <Box color="#FFFFFF">
              <Text fontSize="sm" fontWeight="400" pt="3">
                Contact Us
              </Text>
              <Text fontSize="sm" fontWeight="400" pt="3">
                +919650255062, +919939397519
              </Text>

              <Text fontSize="sm" fontWeight="400" pt="3">
                vishalkumar70522@gmail.com
              </Text>
              <Flex gap="10" align="center" justifyContent="center" pt="1">
                <FaFacebook color="#FFFFFF" size="20px" />
                <BsInstagram color="#FFFFFF" size="20px" />
                <BsYoutube color="#FFFFFF" size="25px" />
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      </Show>
    </Box>
  );
}
