import {
  Box,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { shopList } from "../../data";
import { BalajeeMap } from "../../accets";
import { IoMdCall } from "react-icons/io";
import { PiMapPinLineFill } from "react-icons/pi";
import { AiOutlineUser, AiTwotoneShop } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";

export function ContactUs() {
  return (
    <Box>
      <Box
        backgroundImage={
          "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha"
        }
        height={{ base: "200px", lg: "800px" }}
        width="100%"
        bgSize="cover"
      >
        <Text
          align="center"
          pt={{ base: "20", lg: "400" }}
          fontSize={{ base: "4xl", lg: "6xl" }}
          fontWeight="600"
          color="#FFFFFF"
        >
          Contact Us
        </Text>
      </Box>
      <Center
        bgColor="#f58634"
        color="#2A2A2A"
        py={{ base: "3", lg: "10" }}
        px={{ base: "5" }}
      >
        <SimpleGrid columns={[1, 1, 2]} spacing={{ base: "5", lg: "40" }}>
          <Box>
            <Text fontSize={{ base: "lg", lg: "3xl" }} fontWeight="600">
              MANUFACTURING UNITS :
            </Text>
            <Text
              fontSize={{ base: "md", lg: "xl" }}
              fontWeight="400"
              width={{ base: "", lg: "200px" }}
            >
              Tetaria more, Ekawana, Ara Bhojpur, Bihar - 802301
            </Text>
            <Text
              fontSize={{ base: "lg", lg: "3xl" }}
              fontWeight="600"
              pt={{ base: "3", lg: "10" }}
            >
              PHONE :
            </Text>
            <Text fontSize={{ base: "md", lg: "xl" }} fontWeight="400">
              Tel : +919650255062 , +918252285896
            </Text>
            <Text
              fontSize={{ base: "lg", lg: "3xl" }}
              fontWeight="600"
              pt={{ base: "1", lg: "3" }}
            >
              EMAIL :
            </Text>
            <Text fontSize={{ base: "md", lg: "xl" }} fontWeight="400">
              Vishalkumar70522@gmail.com
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: "lg", lg: "3xl" }} fontWeight="600">
              REGISTERED OFFICE :
            </Text>
            <Text
              fontSize={{ base: "md", lg: "xl" }}
              fontWeight="400"
              width={{ base: "", lg: "200px" }}
            >
              Balajee Steel Shop, Near Bus Stand, Ara Bhojpur, Bihar - 802301
            </Text>
            <Text
              fontSize={{ base: "lg", lg: "3xl" }}
              fontWeight="600"
              pt={{ base: "3", lg: "10" }}
            >
              PHONE :
            </Text>
            <Text fontSize={{ base: "md", lg: "xl" }} fontWeight="400">
              Tel : +919650255062 , +919939397519
            </Text>
            <Text
              fontSize={{ base: "lg", lg: "3xl" }}
              fontWeight="600"
              pt={{ base: "1", lg: "3" }}
            >
              EMAIL :
            </Text>
            <Text fontSize={{ base: "md", lg: "xl" }} fontWeight="400">
              Vishalkumar70522@gmail.com
            </Text>
          </Box>
        </SimpleGrid>
      </Center>
      <Tooltip label="Click to see on google map">
        <Image
          src={BalajeeMap}
          alt=" "
          height={{ base: "200px", lg: "500px" }}
          width="100%"
          onClick={() =>
            window.open(
              "https://www.google.com/maps/place/Balajee+engineering+works/@25.5209514,84.6346561,16.31z/data=!4m6!3m5!1s0x398d5f800d163033:0x2e56a84df5854253!8m2!3d25.5199473!4d84.6395915!16s%2Fg%2F11kgp4bsv2?entry=ttu",
              "_blank"
            )
          }
        />
      </Tooltip>
      <Box p={{ base: "2", lg: "10" }}>
        <SimpleGrid columns={[1, 2, 3]} spacing={{ base: "1", lg: "10" }}>
          {shopList?.map((shop, index) => (
            <Box
              p="5"
              key={index}
              border="1px"
              borderColor="#D9D9D9"
              _hover={{ boxShadow: "2xl" }}
            >
              <Flex align="center" gap="2">
                <AiTwotoneShop color="#f58634" />
                <Flex gap="5">
                  <Text>{shop.shopName}</Text>
                  <Text color="#900C3F">{shop.shopType}</Text>
                </Flex>
              </Flex>
              <Flex align="center" gap="2">
                <PiMapPinLineFill color="#f58634" />
                <Text>{shop.fullAddress}</Text>
              </Flex>

              <Flex align="center" gap="2" pt="2">
                <AiOutlineUser color="#f58634" />
                <Text>{shop.OwnerName}</Text>
              </Flex>
              <Flex align="center" gap="2" pt="2">
                <IoMdCall color="#f58634" />
                <Text>{shop.tel}</Text>
              </Flex>
              <Flex align="center" gap="2" pt="2">
                <BsClockHistory color="#f58634" />
                <Text>{shop.open}</Text>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
