export const ballImages = [
  {
    image:
      "https://bafybeie7yz7cozpggfqhfokvvieqx6ajhityppjuveqeopzas3rwkbilja.ipfs.w3s.link/ball45.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeidgprtkcazryp7pjrhz3kjxkezflfwrifoahyr2k5resjstxu745a.ipfs.w3s.link/23.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeie7yz7cozpggfqhfokvvieqx6ajhityppjuveqeopzas3rwkbilja.ipfs.w3s.link/ball45.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeibikgrhqpf4ek2fqop3hdd6cllpwnckjez2uhzwilez6shbuss6be.ipfs.w3s.link/ball2.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeib3wlcjskidjrb7prj3q6yuv25r2rcz2acjwda2mzp63eztwj3kim.ipfs.w3s.link/ball4.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeib3wlcjskidjrb7prj3q6yuv25r2rcz2acjwda2mzp63eztwj3kim.ipfs.w3s.link/ball4.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeidlkfjx5fgtossryacrn4w43rceb7dmjregacnk3bpz73mor54y3i.ipfs.w3s.link/ball1.jpeg",
    description: "ball 5' 202, 304",
  },
];
