export const ohterProductImages = [
  "https://bafybeidokgmbtrh6cnoknmyeasirywd4amctnik2m4zgjq3qtqxjbolwxy.ipfs.w3s.link/chul-3.jpg",
  "https://bafybeibcbsv5dd547xhbya3tel547ogwsku4nalln46mbclvofhsp6edvy.ipfs.w3s.link/chul-2.jpg",
  "https://bafybeig5osqy6kcjd2toy4hkbirzzpghvnju6ujyzy4rl65q5ekf36hg7q.ipfs.w3s.link/chul-4.jpg",
  "https://bafybeigij35c3osxrwrplrotgjvv66uf4suiy7zyvtf5xl7ggu3ahw4x4a.ipfs.w3s.link/other-5.jpg",
  "https://bafybeigjj765obh4hnpcof2nexoeh3rhmzocmykyjnssqqhmzzcuv3wjoa.ipfs.w3s.link/other-3.jpg",

  "https://bafybeihiozoxlmklu5qahqvu57n2f7w2olp5fcrog4ttvlyf44knb4vlsa.ipfs.w3s.link/other-4.jpg",
  "https://bafybeifohwyhlrhp7pbjwb2ts23zo5kegouo7capvmzl2ujdiefb25o7ny.ipfs.w3s.link/other-2.jpg",
  "https://bafybeidhqbcxh6qhle3bpvvu3fjbiwxggadn4fp7mfb3wmvgxlbxcq4nim.ipfs.w3s.link/other-6.jpg",
  "https://bafybeicexi4cehiuwdqbse3aqggmhzq3yxzxf75bdpxz4r6mqdflojksm4.ipfs.w3s.link/other-1.jpg",
];
