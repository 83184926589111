export const masterPilerImages = [
  "https://bafybeihmcafxnfwc4jhmqczmoywb7zke3hy3ktlie4zdj53uzir325ereq.ipfs.w3s.link/?filename=c.jpg",
  "https://bafybeibuhvip7ljvc6rioawthlel3nking3ksb7pmcbs6rxefv5fsqfgdu.ipfs.w3s.link/?filename=d.jpg",
  "https://bafybeicujmz2572cjcanekwrezv74b6fhgp4uexs6lmiihorsynsor2rba.ipfs.w3s.link/?filename=a.jpg",
  "https://bafybeifz7sgvcdykltvxazg3aw5ek7doiyvmchnhdfqgl6jtx3glmbqjt4.ipfs.w3s.link/?filename=b.jpg",
  "https://bafybeiacjtafbsdsxx4pumrjyo6jf26ir6zorxpls4vb2whi4f7qhvkd4q.ipfs.w3s.link/IMG_20230803_184507.jpg",
  "https://bafybeieqxsrjmczjvhjkoblbkceiow2llfvhhm66a73pfq3bfgk6ubeixy.ipfs.w3s.link/3.png",
  "https://bafkreidl2bqv7dxnye22vceamm765xlwjmhlulkq76qzenhzjklcjahpxq.ipfs.w3s.link/?filename=8.png",
  "https://bafybeid4vfinasitvgpono7umdmjy4rkq3jopqzyzu7f3gsak3inskgusm.ipfs.w3s.link/9.png",
  "https://bafybeibxonphff3alr5sx7eqjojzaqqxroev2jbg3ojhqaxbskkihs4uuu.ipfs.w3s.link/6.png",
  "https://bafybeiasffh5tkio7nkh6xtf4zfwlvzdgl4teyuuukurxkhba5odtox6lu.ipfs.w3s.link/5.png",
  "https://bafybeifwif3koku6j62mhrosrhy2ejpcb7pgh6673vx7q7cxanjl7ow4ou.ipfs.w3s.link/4.png",
  "https://bafybeidqww2aoyf75lyurknjaq35pgwk7mdfuove2do6oiqb5cbjwum3ji.ipfs.w3s.link/7.png",
  "https://bafybeicajafrav75ft4ixwsbjjy6ouymxloj3bisryqgtjkglequsq6x2e.ipfs.w3s.link/2.png",
];
