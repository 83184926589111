export const designeImages = [
  "https://bafybeia3t4rvurt5efmukwj4bz4l3rprjalqnytpob2vmcwmut4e7zkrra.ipfs.w3s.link/design-1.jpg",
  "https://bafybeie45ktvdig2v34cfsygwuizjlx4l3b3vkf55bownruhanxzhqban4.ipfs.w3s.link/design-2.jpg",
  "https://bafybeigthef4oerjtyscbuv42d4n2bye6wee74xs4b4mkxaevxdnt27pcm.ipfs.w3s.link/design-3.jpg",
  "https://bafybeicjgnkuvqfnak7lrue5dlojzwlfeb6qhpmzm2deqb4v5ra7nfo63e.ipfs.w3s.link/design-4.jpg",
  "https://bafybeidtoyiacijgthfbfx5wgor4wudw3idp72vxi3oxvvkpkpgzvpzn3a.ipfs.w3s.link/design-5.jpg",
  "https://bafybeigq6tacfjpoqs5ss3rbgr2fx3f45nqfwrudvcsu4fmdtyqeh4rr5i.ipfs.w3s.link/design-6.jpg",
  "https://bafybeifbumtsfj64lvrrb2ztutdk7jaegwcebf6rxuohrb32q22nkz3mxu.ipfs.w3s.link/design-7.jpg",
  "https://bafybeieykl7n73jog4gurenjyc5pw6gfxvfbzgsrvd2jt5766luhbzq3hi.ipfs.w3s.link/design-8.jpg",
  "https://bafybeiasqexyc7snisahmcrnllp6kkgy2l7bkx6pdba47ipwvdtg4hdnqi.ipfs.w3s.link/design-9.jpg",
  "https://bafybeifjku4wrx6mlwh4fgcuuw33nmzjcbdsmckq3khhozlm3zo3s3drvu.ipfs.w3s.link/design-10.jpg",
  "https://bafybeieppbpykdcp24vkqale4j2a4qj55xcsxsp7x5hv7q3xj6unmzhgay.ipfs.w3s.link/design-11.jpg",
  "https://bafybeif6pruajm4k43xayuj4b7wmg3fmrjddss3khjzyudvmnwzpghp7gy.ipfs.w3s.link/design-12.jpg",
  "https://bafybeif5tthx6wxbgxecnvokkscytzkgqu4h4cbabdicyvroannbdci2c4.ipfs.w3s.link/design-14.jpg",
  "https://bafybeigr5fbkka4boy6noscdd2rfiqcyjott5qtlca6okdetqeixnaibtu.ipfs.w3s.link/design-15.jpg",
  "https://bafybeiguxvarnmfjekdmkpj5757y736anars46xdivf25jreacjp6yn4mi.ipfs.w3s.link/design-16.jpg",
  "https://bafybeibatjtgh4lry7e6i7spmif23ysjraugf6s5btz6dlrnv5kch5nzte.ipfs.w3s.link/design-17.jpg",
  "https://bafybeib2q5hljhf5bwxaqpgqdj6lx5zscdniu6hxzrug5ll2y7bt6e3cxe.ipfs.w3s.link/design-18.jpg",
  "https://bafybeia6huqhloadf37pgsqkwimkea4fv46e4hrtkhya4c5l2zn3hjodpq.ipfs.w3s.link/design-19.jpg",
];
