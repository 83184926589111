import { baldevPd, dashrath, laldevPd, madankr } from "../accets";

export const cofounder = [
  { image: dashrath, name: "Dashrath Pandit" },
  {
    image: laldevPd,
    name: "Laldeo Pandit",
  },
  // {
  //   image: madankr,
  //   name: "Madan Kumar",
  // },
  // {
  //   image: baldevPd,
  //   name: "Baldeo Pandit",
  // },
];
