export const bendImages = [
  {
    image:
      "https://bafybeifob7yqyz33egfxhystr34nwyfurqkabm7cqzepcaok2e55thlolm.ipfs.w3s.link/bend1.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeigr3bw4mtr7yyhhmb73qomufymmf5fev2rqtwt2odv755qmjaitce.ipfs.w3s.link/bend2.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeidty4cjl7eaqdv6woafekfzflxzmje6tl5qwebvq4ysay3ja5l3z4.ipfs.w3s.link/bend3.jpeg",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeiebzgqnj2mgt4r77jrhz3z7g5inh3agp3r4g56yatj4v3eqtvjlea.ipfs.w3s.link/bend4.jpeg",
    description: "ball 5' 202, 304",
  },
];
