import { visitingCard } from "../accets";

export const productImagesURL = [
  visitingCard,
  "https://bafybeihmcafxnfwc4jhmqczmoywb7zke3hy3ktlie4zdj53uzir325ereq.ipfs.w3s.link/?filename=c.jpg",
  "https://bafybeibuhvip7ljvc6rioawthlel3nking3ksb7pmcbs6rxefv5fsqfgdu.ipfs.w3s.link/?filename=d.jpg",
  "https://bafybeial5c3n5petiur63wqnki4edf5d22t3qixnr7uiw57nvafdmuygjq.ipfs.w3s.link/?filename=e.jpg",
  "https://bafybeicujmz2572cjcanekwrezv74b6fhgp4uexs6lmiihorsynsor2rba.ipfs.w3s.link/?filename=a.jpg",
  "https://bafybeifz7sgvcdykltvxazg3aw5ek7doiyvmchnhdfqgl6jtx3glmbqjt4.ipfs.w3s.link/?filename=b.jpg",
  "https://bafybeiacjtafbsdsxx4pumrjyo6jf26ir6zorxpls4vb2whi4f7qhvkd4q.ipfs.w3s.link/IMG_20230803_184507.jpg",
];
