export const baseImages = [
  {
    image:
      "https://bafybeiavaaf5ybsxiht52lvlnlzt7iisgvgwxmre7uvnxbnkizoyrp4rja.ipfs.w3s.link/base-1.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeigfje6s67qgauf5cuc4ulmefyvu6hrk72mt6plitfzbiirz235avy.ipfs.w3s.link/30.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeietg6jvej7bzmyharbhmmnl27hv4foevsbscg7wrgribvr73niheu.ipfs.w3s.link/31.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeibbk52zv6mojgsb3j45dcpidcvd6pjznqyjikfrp2wvmvn7q3i2du.ipfs.w3s.link/32.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeig7hn6bxtwczgfrwo3uaarn3eyfy3gl4kpnz4a7nb3olh7dqubthi.ipfs.w3s.link/34.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeifu3w4masfpheuikvggdc77yxmfh7732o7ole63laragbifs34c54.ipfs.w3s.link/36.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeie6blwctaxk22xsud3dedtev5673zycj6nb3ofuhomd4sdo3gcm7a.ipfs.w3s.link/37.png",
    description: "ball 5' 202, 304",
  },
  {
    image:
      "https://bafybeidfs55hbv5vb4z4hosqn37t7o2yj2wwecalf22j6e2s3d4j4uhwea.ipfs.w3s.link/38.png",
    description: "ball 5' 202, 304",
  },
];
