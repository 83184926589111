export const cutterImages = [
  "https://bafybeigifhbdmv6bej7hmkr22ri2fgbrfahsnytaxdllkdm5sck3lsvkyy.ipfs.w3s.link/cutter-6.jpg",
  "https://bafybeidf7sguscfxmpibxd56wvog6u2nprjk4p3tz332l7is6h6v4jumsa.ipfs.w3s.link/cutter-8.jpeg",
  "https://bafybeihrdeptgw7ouiaxk7qowmxp45tgxgn6p45b7lrptor3t4pmzp3eau.ipfs.w3s.link/cutter-5.jpg",
  "https://bafybeiczsfn3c5hyipcjz7tcchxsos5xx5s2drrfq5z7ahxndoypw3hnze.ipfs.w3s.link/cutter-1.jpg",
  "https://bafybeiaovcjlfclht7phlymb24ok5kfaxzriky7ye6van4criuxsgohtu4.ipfs.w3s.link/cutter-3.jpg",
  "https://bafybeicm2wo3t4fgelszdia42jx2sebziwvsgkhr2jwjhfasmzqbxue5ji.ipfs.w3s.link/cutter-2.jpg",
  "https://bafybeiajtt2sk7hyvcfljnfqf4kgnhbcu4umfvdkiwwbevwxgq6ql77mpm.ipfs.w3s.link/cutter-7.jpg",
  "https://bafybeifirnls7g4g22fs662gevbqhqs5pwbpz7hlni5ysq3nkfhyo4nuiu.ipfs.w3s.link/cutter-4.jpg",
];
