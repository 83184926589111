import * as React from "react";

// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import { Routers } from "./routers";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <ChakraProvider>
      <Router>
        <Routers />
      </Router>
    </ChakraProvider>
  );
}

export default App;
