import { anil, baldevPd, dashrath, laldevPd, upendra } from "../accets";

export const shopList = [
  {
    shopType: "Manufacturing unit",
    ownerImage: laldevPd,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",
    shopId: "1234",
    shopName: "Balajee Engineering Works",
    OwnerName: "Laldew pandit",
    fullAddress: "Tetaria more, EKawana, Ara Bhojpur, Bihar - 802301",
    tel: "9650255062",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.520612, 84.6401258],
    locationUrl:
      "https://www.google.com/maps/place/Balajee+engineering+works/@25.520612,84.6401258,18.72z/data=!4m6!3m5!1s0x398d5f800d163033:0x2e56a84df5854253!8m2!3d25.5199473!4d84.6395915!16s%2Fg%2F11kgp4bsv2?entry=ttu",
    redirect: "/shop-details/laldevPandit",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },
  {
    shopType: "Head Office",
    ownerImage: baldevPd,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",

    shopId: "1236",
    shopName: "Balajee Steel Shop",
    OwnerName: "Baldev pandit",
    fullAddress: "Near Bus stand, Ara Bhojpur, Bihar - 802301",
    tel: "9939397519",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.5940947, 85.1375645], //[lat,lng]
    redirect: "/shop-details/busStand",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },
  {
    shopType: "Branches",
    ownerImage: dashrath,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",

    shopId: "1235",
    shopName: "Balajee Engineering Works",
    OwnerName: "Dashrath pandit",
    fullAddress: "Tetaria more, Ekawana, Ara Bhojpur, Bihar - 802301",
    tel: "6299370233",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.549148, 84.67027],
    redirect: "/shop-details/dashrathPandit",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },

  {
    shopType: "Branches",
    ownerImage: baldevPd,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",

    shopId: "1237",
    shopName: "Balajee Aluminium Shop",
    OwnerName: "Baldev pandit",
    fullAddress: "Near Bus stand, Ara Bhojpur, Bihar - 802301",
    tel: "9939397519",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.5578464, 84.6718308],
    redirect: "/shop-details/busStand",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },
  {
    shopType: "Branches",
    ownerImage: baldevPd,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",

    shopId: "1238",
    shopName: "Balajee Loha Dukan",
    OwnerName: "Baldev pandit",
    fullAddress: "Near Bus stand, Ara Bhojpur, Bihar - 802301",
    tel: "9939397519",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.5578464, 84.6718308],
    redirect: "/shop-details/busStand",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },
  {
    shopType: "Branches",
    ownerImage: anil,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",
    shopId: "1240",
    shopName: "Balajee teel Ferniture House",
    OwnerName: "Anil Kumar",
    fullAddress: "New police line, Ara, Bhojpur, Bihar - 802301",
    tel: "9931182244",
    email: "vishalkumar70522@gmail.com",
    city: "Ara",
    location: [25.4046786, 84.5523604],
    redirect: "/shop-details/anilKumar",
    open: "Mon-Sat 8:00 am - 6:00 pm  ",
  },
  {
    shopType: "Branches",
    ownerImage: upendra,
    image:
      "https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha",
    shopId: "1239",
    shopName: "Balajee Aluminium House",
    OwnerName: "Upendra pandit",
    fullAddress: "Ara sasaram road, Garhani bajar, Bhojpur, Bihar - 802301",
    tel: "9708070028",
    email: "info@balajienggworkss.com",
    open: "Mon-Sun 8:00 am - 6:00 pm  ",
    city: "Ara",
    location: [25.4046786, 84.5523604],
    redirect: "/shop-details/upendarPandit",
  },
];
