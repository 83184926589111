import {
  Box,
  Button,
  Flex,
  Hide,
  Image,
  Show,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import "./index.css";
import { logo } from "../../../accets";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

export function Nav() {
  const navigate = useNavigate();
  const homePopover = (
    <Box p={0}>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        SS Pipe
      </Text>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        SS Bluster
      </Text>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        Steel fancy gate
      </Text>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        Steel ralings
      </Text>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        Iron gate
      </Text>
      <Text
        Color="#000000"
        fontSize="md"
        align="left"
        pl="2"
        pr="10"
        py="1"
        _hover={{ bgColor: "gray.500", color: "#FFFFFF" }}
      >
        Windows
      </Text>
    </Box>
  );
  return (
    <Box p="3" bgColor="#2A2A2A">
      <Flex justifyContent="space-between">
        <Stack pl={{ base: "5", lg: "20" }}>
          <Image
            src={logo}
            height="50px"
            width="100px"
            onClick={() => navigate("/")}
          />
        </Stack>
        <Hide below="md">
          <Flex>
            <Button
              variant="null"
              color="#FFFFFF"
              fontSize="20px"
              _hover={{ color: "#f58634" }}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
            <Button
              variant="null"
              color="#FFFFFF"
              fontSize="20px"
              _hover={{ color: "#f58634" }}
              onClick={() => navigate("/")}
            >
              About us
            </Button>
            <Button
              variant="null"
              color="#FFFFFF"
              fontSize="20px"
              _hover={{ color: "#f58634" }}
              onClick={() => navigate("/products")}
            >
              Products & Services
            </Button>
            <Button
              variant="null"
              color="#FFFFFF"
              fontSize="20px"
              _hover={{ color: "#f58634" }}
              onClick={() => navigate("/contactUs")}
            >
              Contact Us
            </Button>
            <Button
              variant="null"
              color="#FFFFFF"
              fontSize="20px"
              _hover={{ color: "#f58634" }}
              onClick={() => navigate("/gallery")}
            >
              Gallery
            </Button>
          </Flex>
        </Hide>
        <Show below="md">
          <Menu>
            <MenuButton>
              <GiHamburgerMenu size="25px" color="#FFFFFF" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate("/")}>About us</MenuItem>
              <MenuItem onClick={() => navigate("/products")}>
                {" "}
                Products & Services
              </MenuItem>
              <MenuItem onClick={() => navigate("/contactUs")}>
                Contact Us
              </MenuItem>
              <MenuItem onClick={() => navigate("/gallery")}>Gallery</MenuItem>
            </MenuList>
          </Menu>
        </Show>
      </Flex>
    </Box>
  );
}
