import { Box, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { productList } from "../../data";
import { useNavigate } from "react-router-dom";

export function Products() {
  const navigate = useNavigate();
  return (
    <Box px={{ base: "2", lg: "20" }} py={{ base: "2", lg: "10" }}>
      <SimpleGrid columns={[2, 2, 3]} spacing={{ base: "5", lg: "20" }}>
        {productList?.map((data, index) => (
          <Box
            key={index}
            bgColor="#0B666A"
            borderRadius="16px"
            p={{ base: "2", lg: "4" }}
            onClick={() =>
              navigate(`/products/${data.name}`, {
                state: {
                  data: data.products,
                  name: data.name,
                },
              })
            }
          >
            <Image
              src={data.image}
              alt={data.name}
              height={{ base: "150px", lg: "310px" }}
              width="100%"
              borderRadius="16px"
            />
            <Stack pt={{ base: "1", lg: "3" }}>
              <Text
                fontWeight="600"
                align="center"
                py={{ base: "1", lg: "3" }}
                color="#FFFFFF"
                m="0"
                borderRadius="40px"
                _hover={{ bgColor: "orange" }}
                onClick={() =>
                  navigate(`/products/${data.name}`, {
                    state: {
                      data: data.products,
                      name: data.name,
                    },
                  })
                }
              >
                {data.name}
              </Text>
            </Stack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}
