export const blusterImages = [
  {
    image:
      "https://bafybeicjumbpje5y2mzuy3uej3llj75qiq4azpeudom4fhwzcluuzzaigu.ipfs.w3s.link/16.png",
    description: "304/202 - 600g-3h-golaDot",
  },
  {
    image:
      "https://bafybeid6g6qrvuzqddeungocfyj7t42o7ln7w3pbox36dobycfyv6oxkz4.ipfs.w3s.link/12.png",
    description: "304/202 D-ball-3H",
  },
  {
    image:
      "https://bafybeibrbvhdski4lumwafzvgjel4z34ne5vd5mgfzlyeeoa6lpat77v2i.ipfs.w3s.link/11.png",
    description: "304/202 Matka-3H",
  },
  {
    image:
      "https://bafybeiahhp7qgsltvk3u33k6io5w4rk4cojccsemd6oyvzfeepjnsehjh4.ipfs.w3s.link/17.png",
    description: "304/202 Ring-Dot-3H",
  },
  {
    image:
      "https://bafybeicnaoj4dvriyx637yolsrzrtlxcbdhphsu6pu6c73j7udzmxogsjy.ipfs.w3s.link/13.png",
    description: "304/202 Rassa-3H",
  },
  {
    image:
      "https://bafybeia3evgygzqbzyzdklks7rtdgfvlvg4fr7dlsncj5zrwyt27fo73uy.ipfs.w3s.link/18.png",
    description: "304/202 Matka-twist-3h",
  },
  {
    image:
      "https://bafybeicnaoj4dvriyx637yolsrzrtlxcbdhphsu6pu6c73j7udzmxogsjy.ipfs.w3s.link/13.png",
    description: "304/202 Rassa-3H",
  },
  {
    image:
      "https://bafybeifsyaityvzaxdjox2pvbucasdvih7hdckoaezuem7m63utvzzwdom.ipfs.w3s.link/15.png",
    description: "304/202 Ring-Dot-D.ball-3H",
  },
  {
    image:
      "https://bafybeihgutqmjbczobbpmb7trfoyeitanzftfzwimd5dt6k655cjutyj4y.ipfs.w3s.link/14.png",
    description: "304/202 Baluster-3H",
  },
  {
    image:
      "https://bafybeieuwhbcw2gpuelkbmig2tnbobqnjn6j23vpnj7ipqojb6zplml7iq.ipfs.w3s.link/10.png",
    description: "304/202- Squar-3h",
  },
];
