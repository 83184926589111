import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Image } from "antd";

export function SingleProductType(props) {
  const location = useLocation();
  const products = location.state.data;
  const productName = location.state.name;

  return (
    <Box
      px={{ base: "2", lg: "20" }}
      py={{ base: "2", lg: "10" }}
      bgColor="#E8FFCE"
    >
      <Text
        fontWeight={600}
        fontSize={{ base: "24px", lg: "32px" }}
        color=""
        align="center"
      >
        {productName}
      </Text>

      <SimpleGrid
        columns={[2, 2, 3]}
        spacing={{ base: "5", lg: "20" }}
        pt={{ base: "5", lg: "10" }}
      >
        {products?.map((data, index) => (
          <Box bgColor="#0B666A" p={{ base: "1", lg: "3" }} key={index}>
            <Image.PreviewGroup items={[data?.image || data]}>
              <Image width="100%" src={data?.image || data} />
            </Image.PreviewGroup>
            {/* <Image
              key={index}
              alt={""}
              src={data?.image || data}
              height={{ base: "180px", lg: "410px" }}
              width="100%"
              borderRadius="16px"
            /> */}
            <Text
              fontSize={{ base: "12px", lg: "24px" }}
              fontWeight={565}
              color="#FFFFFF"
              py="1"
              align="center"
            >
              {data?.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}
