import { Route, Routes } from "react-router-dom";
import { Footer, Nav } from "../Components/common";
import {
  ContactUs,
  Gallery,
  HomePage,
  Products,
  ShopDetails,
  SingleProductType,
} from "../pages";

export function Routers() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:name" element={<SingleProductType />} />
        <Route path="/gallery" element={<Gallery />} />

        <Route path="/shop-details/:shopId" element={<ShopDetails />} />
      </Routes>
      <Footer />
    </>
  );
}
