import {
  ballImages,
  baseImages,
  bendImages,
  blusterImages,
  corebitsImages,
  cutterImages,
  designeImages,
  kanchaImages,
  locksImages,
  masterPilerImages,
  ohterProductImages,
  startWindow,
} from "./products";

export const productList = [
  {
    image:
      "https://bafybeieqxsrjmczjvhjkoblbkceiow2llfvhhm66a73pfq3bfgk6ubeixy.ipfs.w3s.link/3.png",

    name: "Master piler",
    products: masterPilerImages,
  },
  {
    image:
      "https://bafybeifsyaityvzaxdjox2pvbucasdvih7hdckoaezuem7m63utvzzwdom.ipfs.w3s.link/15.png",

    name: "Bluster",
    products: blusterImages,
  },
  {
    image:
      "https://bafybeigjosho66ffaquifrzmhrmf56qtf73qrbtnvvgohscx3ux6q6bzwy.ipfs.w3s.link/20.png",

    name: "Star window",
    products: startWindow,
  },

  {
    image:
      "https://bafybeihlixonvjlot2dbo6enit4wcjh2itqyym5nitvbbdnol47a5wgv7u.ipfs.w3s.link/ball11.jpeg",
    name: "Balls",
    products: ballImages,
  },
  {
    image:
      "https://bafybeiavaaf5ybsxiht52lvlnlzt7iisgvgwxmre7uvnxbnkizoyrp4rja.ipfs.w3s.link/base-1.png",
    name: "Base",
    products: baseImages,
  },
  {
    image:
      "https://bafybeigifhbdmv6bej7hmkr22ri2fgbrfahsnytaxdllkdm5sck3lsvkyy.ipfs.w3s.link/cutter-6.jpg",
    name: "Cutter",
    products: cutterImages,
  },
  {
    image:
      "https://bafybeibwvmxkiaqiukz6fzm4z3ntjc562aezwsr35eolxwgvultjrr3rni.ipfs.w3s.link/lock1.jpg",
    name: "Locks",
    products: locksImages,
  },
  {
    image:
      "https://bafybeicgrfx3nby3irocfsah7dmexsrdzuodrawvc5y3zskc2iwjshsc6y.ipfs.w3s.link/kancha1.jpg",
    name: "Kancha",
    products: kanchaImages,
  },
  {
    image:
      "https://bafybeifob7yqyz33egfxhystr34nwyfurqkabm7cqzepcaok2e55thlolm.ipfs.w3s.link/bend1.jpeg",
    name: "Bend",
    products: bendImages,
  },
  {
    image:
      "https://bafybeihoclpty4egjjzimjzv5lb6lp2wrxvn5axpcscqxh3idvjtcexk3q.ipfs.w3s.link/bits1.jpeg",
    name: "Core bit",
    products: corebitsImages,
  },
  {
    image:
      "https://bafybeia3t4rvurt5efmukwj4bz4l3rprjalqnytpob2vmcwmut4e7zkrra.ipfs.w3s.link/design-1.jpg",
    name: "Design",
    products: designeImages,
  },
  {
    image:
      "https://bafybeihiozoxlmklu5qahqvu57n2f7w2olp5fcrog4ttvlyf44knb4vlsa.ipfs.w3s.link/other-4.jpg",
    name: "Other products",
    products: ohterProductImages,
  },
];
